import { useGoogleAuth } from './hooks/useGoogleAuth'
import { handleFirebaseErrors } from './utils/handleFirebaseErrors'
import { UserCredential } from 'firebase/auth'
import axios from 'axios'
import { API_URL } from './data/constants'
import { useState } from 'react'
import { handleApiErrors } from './utils/handleApiErrors'
import { styled } from 'styled-components'
import { ToastContainer,toast } from 'react-toastify'
import googleLogo from './assets/googleLogo.svg'

import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const [loading,setLoading] = useState(false)
  const [token,setToken] = useState(``)
  const signInWithGoogle = useGoogleAuth()

  const saveUser = async(auth: UserCredential) => {
    if(!auth?.user) return
  
    const idToken = await auth.user.getIdToken()

    try {
      const res = await axios.post(`${API_URL}/`,{ idToken })
      setToken(res.data.access_token)
    } catch (error) {
      handleApiErrors(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleClick = () => {

    setLoading(true)
    signInWithGoogle().then(saveUser).catch(e => {
      setLoading(false)
      handleFirebaseErrors(e)
    })
  }

  const handleCopyToClipBoard = async() => {
    await navigator.clipboard.writeText(token)
    toast.success(`Token copied!`)
  }

  return (
    <Container>
      <Content>
        <Card>
          <Image src="/wakeflow.png" />
          <CardBody>
            {token ? (
              <Token>
                <TokenText>{token}</TokenText>
                <CopyButton onClick={handleCopyToClipBoard}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/>
                  </svg>
                </CopyButton>
              </Token>
            ) : <Button onClick={handleGoogleClick} $loading={loading}>Sign in with <BrandLogo src={googleLogo}/></Button>}
          </CardBody>
        </Card>
      </Content>
      <ToastContainer theme="dark"/>
    </Container>
  )
}

export default App

const Container = styled.div`
`
const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Card = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  background-color: rgba(255,255,255,0.15);
  box-shadow: 0 10px 20px rgba(0,0,0,0.15);
`
const CardBody = styled.div`
  padding: 16px 32px;
  padding-bottom: 32px;
`
const Token = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`
const TokenText = styled.span`
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  word-break: break-all;
`
const Image = styled.img`
  width: 100%;
`
const Button = styled.button<{$loading: boolean}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 28px 12px 32px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  border: none;
  /* border-radius: 4px 4px 4px 4px; */
  border-radius: 100px;
  transition: all .2s;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0,0,0,0.15);

  ${p => p.$loading && `
    pointer-events: none;
    opacity: 0.75;
  `}

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(0,0,0,0.15);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
  }
`
const CopyButton = styled.button`
  font-size: 18px;
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
`
const BrandLogo = styled.img`
  width: 16px;
  height: 16px;
`
