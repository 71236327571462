export const API_URL = process.env.REACT_APP_API_URL as string

interface IFirebaseErrorMessages {
  [key: string]: string
}

export const FIREBASE_ERROR_MESSAGES: IFirebaseErrorMessages = {
  "auth/user-not-found": `Email or password is wrong!`,
  "auth/wrong-password": `Email or password is wrong!`,
  "auth/email-already-in-use": `This account already exists!`,
  "auth/popup-closed-by-user": `Popup closed by user`,
}
